import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistStateReducer } from '../../persistence'

export const slice = createSlice({
  name: 'context',
  initialState: {
    companyId: 0,
    companyName: ``,
    resourceId: 0,
    resourceName: ``,
    resourceCode: ``,
    isResourceConfirmed: false,
    isSelectingResource: false,
    isAdmin: false,
  },
  reducers: {
    setCompany: (state, action: PayloadAction<{ id: number; name: string }>) => {
      state.companyId = action.payload.id
      state.companyName = action.payload.name
      //state.resourceId = state.isAdmin ? `` : state.resourceId
      state.isResourceConfirmed = false
      state.isSelectingResource = true
    },
    setResource: (state, action: PayloadAction<{ id: number; name: string; code: string }>) => {
      state.resourceId = action.payload.id
      state.resourceName = action.payload.name
      state.resourceCode = action.payload.code
      state.isResourceConfirmed = true
      state.isSelectingResource = false
    },
    setAdmin: state => {
      state.isAdmin = true
    },
    showResourceDialog: state => {
      if (state.isAdmin) {
        state.isSelectingResource = true
      }
    },
    closeResourceDialog: state => {
      state.isSelectingResource = false
    },
    expectsResource: state => {
      if (!state.isResourceConfirmed && !state.resourceId && state.isAdmin) {
        state.isSelectingResource = true
      }
    },
  },
})

export const { setCompany, setResource, closeResourceDialog, showResourceDialog, expectsResource, setAdmin } =
  slice.actions

export default persistStateReducer(`app-context`, slice.reducer)
