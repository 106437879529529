import React, { useMemo } from 'react'

export interface Settings {
  version: string
  gateway: string
  login: string
  clientId: string
  instrumentationKey: string
}

export const StateSettings = ({ children }: { children: React.ReactNode }) => {
  const values = useMemo(
    () => ({
      version: getVersion(),
      gateway: getGateway(),
      login: getLogin(),
      clientId: getClientId(),
      instrumentationKey: getInstrumentationKey(),
    }),
    [],
  )
  return <StateSettings.Context.Provider value={values}>{children}</StateSettings.Context.Provider>
}
StateSettings.Context = React.createContext<Settings>({
  version: ``,
  gateway: ``,
  login: ``,
  clientId: ``,
  instrumentationKey: ``,
})

export const getVersion = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-version"]`) as HTMLMetaElement)?.content ?? ``
export const getGateway = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-gateway"]`) as HTMLMetaElement)?.content ?? ``
export const getLogin = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-login"]`) as HTMLMetaElement)?.content ?? ``
export const getClientId = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-clientId"]`) as HTMLMetaElement)?.content ?? ``
export const getInstrumentationKey = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-instrumentationKey"]`) as HTMLMetaElement)?.content ?? ``
