import { NoSsr } from '@mui/material'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import { Alerts, MpriseTheme } from '@mprise/react-ui'
import { enableMapSet } from 'immer'
import React, { Suspense, useContext, useState } from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import './index.css'
import './lib/translations/i18n'
import { Splash } from './splash'
import { serviceWorkerInitialized, serviceWorkerUpdated } from './state/actions/app/serviceWorker'
import { StateSettings } from './state/settings'
import { persistor, store } from './state/store'
import * as serviceWorker from './serviceWorker'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import { ErrorBoundary } from './error-boundary'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getLogin, getClientId, getGateway } from './state/settings'
import { MpriseMuiThemeProvider } from './theme'

OfflinePluginRuntime.install({
  onInstalled() {
    OfflinePluginRuntime.update()
  },
})

const fetchTokenFromStorage = () => {
  const token = sessionStorage.getItem('oidc.user:' + getLogin() + ':' + getClientId())

  if (token) {
    const tokenInfo = JSON.parse(token)
    return tokenInfo['access_token']
  } else {
    return ''
  }
}

const httpLink = createHttpLink({
  uri: getGateway(),
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = fetchTokenFromStorage()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

const mergePlainArrays = (existing: any, incoming: any, { args }: any) => {
  const merged = existing ? existing.slice(0) : []
  for (let i = 0; i < incoming?.length; ++i) {
    merged[(args?.offset ?? 0) + i] = incoming[i]
  }
  return merged
}

const mergeFunctionWithPageAndTotal = (existing: any, incoming: any, { args }: any) => {
  const merged = existing ? existing.page.slice(0) : []
  for (let i = 0; i < incoming?.page?.length; ++i) {
    merged[(args?.offset ?? 0) + i] = incoming.page[i]
  }
  return { page: merged, total: incoming.total }
}

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          jobs: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          trackingIds: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          lots: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          positions: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          searchWorkTasks: {
            keyArgs: ['filter'],
            merge: mergePlainArrays,
          },
          searchWorkItems: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          searchRpgWorkItems: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          resources: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          positionsByJobs: {
            keyArgs: ['jobIds'],
            merge: mergePlainArrays,
          },
          jobsByPositions: {
            keyArgs: ['positionIds'],
            merge: mergePlainArrays,
          },
        },
      },
    },
  }),
})

const LazyAppRoot = React.lazy(() => import(`./app/root`).then(x => ({ default: x.AppRoot })))

const startup = async () => {
  enableMapSet()

  const root = ReactDOM.createRoot(document.getElementById('root')!)

  root.render(
    <React.StrictMode>
      <MpriseTheme />
      <MpriseMuiThemeProvider>
        <ErrorBoundary>
          <Suspense fallback={<Splash />}>
            <NoSsr>
              <BrowserRouter>
                <StateSettings>
                  <PersistGate loading={<Splash />} persistor={persistor}>
                    <Provider store={store}>
                      <Root />
                    </Provider>
                  </PersistGate>
                </StateSettings>
              </BrowserRouter>
            </NoSsr>
          </Suspense>
        </ErrorBoundary>
      </MpriseMuiThemeProvider>
    </React.StrictMode>,
  )
}

const Root = () => {
  const settings = useContext(StateSettings.Context)
  const [mpriseId] = useState(() => new MpriseIdentityServer(settings.login, settings.clientId))
  return (
    <MpriseAuthProvider mpriseId={mpriseId}>
      <ApolloProvider client={apolloClient}>
        <Alerts>
          <LazyAppRoot />
        </Alerts>
      </ApolloProvider>
    </MpriseAuthProvider>
  )
}

const startTime = Date.now()
startup()
  .then(() => {
    const duration = Date.now() - startTime
    console.log(`Initialized in ${(duration / 1000).toFixed(1)}s`)
  })
  .catch(error => {
    console.error(`Startup error:`, error)
    const root = document.getElementById('root')
    if (root) {
      root.innerText = `Oops! ${String(error.message ?? error)}`
    }
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch(serviceWorkerInitialized()),
  onUpdate: reg => store.dispatch(serviceWorkerUpdated(reg)),
})
