import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  initialized: boolean
  updated: boolean
  instance: ServiceWorkerRegistration | null
}

const initialState: InitialState = {
  initialized: false,
  updated: false,
  instance: null,
}

export const slice = createSlice({
  name: 'serviceWorker',
  initialState,
  reducers: {
    serviceWorkerInitialized: state => {
      state.initialized = !state.initialized
      return state
    },
    serviceWorkerUpdated: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
      state.updated = !state.updated
      state.instance = action.payload
    },
  },
})

export const { serviceWorkerInitialized, serviceWorkerUpdated } = slice.actions

export default slice.reducer
