import { Action, Reducer } from '@reduxjs/toolkit'
import localForage from 'localforage'
import { persistReducer, Storage } from 'redux-persist'

const storage = localForage.createInstance({
  name: `redux-starter`,
  driver: localForage.LOCALSTORAGE,
})
const sessionStorageDriver: Storage = {
  getItem: k => Promise.resolve(sessionStorage.getItem(k)),
  setItem: (k, v) => Promise.resolve(sessionStorage.setItem(k, v)),
  removeItem: k => Promise.resolve(sessionStorage.removeItem(k)),
}

export const persistStateReducer = <S extends unknown, A extends Action<any>>(
  reducerStorageKey: string,
  reducer: Reducer<S, A>,
) => {
  return persistReducer({ key: reducerStorageKey, storage }, reducer)
}

export const sessionPersistStateReducer = <S extends unknown, A extends Action<any>>(
  reducerStorageKey: string,
  reducer: Reducer<S, A>,
  args?: { blacklist: Array<Extract<keyof S, string>> },
) => {
  return persistReducer(
    {
      key: reducerStorageKey,
      storage: sessionStorageDriver,
      blacklist: args?.blacklist,
    },
    reducer,
  )
}
