import { combineReducers } from '@reduxjs/toolkit'
import context from './context'
import locale from './locale'
import scanning from './scanning'
import serviceWorker from './serviceWorker'

export default combineReducers({
  context,
  locale,
  scanning,
  serviceWorker,
})
