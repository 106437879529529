import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistStateReducer } from '../../persistence'

const initialState = { selected: 'en' }

export const slice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.selected = action.payload
    },
  },
})

export const { setLocale } = slice.actions

export default persistStateReducer(`app-locale`, slice.reducer)
