import { px } from '@mprise/react-ui'
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material'

const fontFamily = "Poppins, Roboto, 'Segoe UI', sans-serif"

// WARNING: The content of this file is copied over from our @mprise/react-ui library. For some reason, when the theme is imported
// from the library directly, it runs into a "Maximum call stack size exceeded" error.
// This has to be fixed, but for now it is solved with a duplication/copy of MpriseMuiThemeOptions and MpriseMuiThemeProvider in
// order to save time.

export const MpriseMuiThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: fontFamily,
    fontWeightLight: 300,
    fontWeightMedium: 300,
    fontWeightRegular: 300,
    fontWeightBold: 500,
    body1: { fontSize: px(18) },
    body2: { fontSize: px(18) },
    h1: { fontSize: px(20) },
    h2: { fontSize: px(20) },
    h3: { fontSize: px(20) },
    h4: { fontSize: px(20) },
    h5: { fontSize: px(20) },
    h6: { fontSize: px(20) },
    caption: { fontSize: px(18) },
    subtitle1: { fontSize: px(20) },
    subtitle2: { fontSize: px(18) },
    button: { fontSize: px(18), textTransform: `none` },
  },
  palette: {
    primary: {
      // light: '#35ae6a',
      main: '#009a44',
      // dark: '#026b30'
    },
    secondary: {
      main: '#33cc33',
    },
    background: {
      default: '#F7F7F7',
      paper: '#F7F7F7',
    },
  },
  components: {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
}

const themeInstance = createTheme(MpriseMuiThemeOptions)
export const MpriseMuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={themeInstance}>
      <div className={`mprise-mui`}>{children}</div>
    </ThemeProvider>
  )
}
